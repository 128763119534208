import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import diagramImg from "../../images/shoptet-easy-diagram-bright.png"

import "./Heliosxshoptet.scss"

const Heliosxshoptet = () => {
    const people = useStaticQuery(graphql`
    query {
        cvejn: file(relativePath: { eq: "people/cvejn.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }`);

    return (
        <section id="heliosxshoptet-page" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="heliosxshoptet.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <FormattedHTMLMessage id="heliosxshoptet.description" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="subtitle">
                            <FormattedMessage id="heliosxshoptet.advantages.title" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8">
                        <ul className="custom-list">
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point1"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point2"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point3"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point4"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point5"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.advantages.point6"/></li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="subtitle">
                            <FormattedMessage id="heliosxshoptet.howItWorks.title" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="big-text">
                            <FormattedMessage id="heliosxshoptet.howItWorks.points.title" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8">
                        <ul className="custom-list">
                            <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.points.point1"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.points.point2"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.points.point3"/>
                                <ul className="custom-list">
                                    <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.dataManager.point1"/></li>
                                    <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.dataManager.point2"/></li>
                                    <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.dataManager.point3"/></li>
                                    <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.dataManager.point4"/></li>
                                </ul>
                            </li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.points.point4"/></li>
                            <li><FormattedHTMLMessage id="heliosxshoptet.howItWorks.points.point5"/></li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <img alt="" className="promo-image" src={diagramImg}></img>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="subtitle-center">
                            <FormattedMessage id="heliosxshoptet.contactus" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="card-team">
                        <div className="imgBox">
                            <Img fluid={people.cvejn.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Jakub Cvejn</h5>
                                <h6 className="card-subtitle text-muted">Obchodní manažer</h6>
                                <div className="contact-section">
                                <p className="user-mobile"><i className="fa fa-phone-square fa-2x contact-icon" aria-hidden="true"></i>+420 728 730 204</p>
                                    <a href="mailto:jakub.cvejn@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>jakub.cvejn@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Heliosxshoptet