import * as React from "react"
import Heliosxshoptet from "../components/Heliosxshoptet/Heliosxshoptet";
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Helios2shoptetPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "title" })}
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco, shoptet, connector']}
      />
      <Heliosxshoptet/>
    </Layout>
  )
}

export default Helios2shoptetPage
